import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignUp from './Promix/Signup/Signup.js';
import Login from './Promix/Login/Login.js';
import Dashboard from './Pages/Dashboard/Dashboard.js';
// import PaymentsHistory from './Pages/Payments/PaymentsInvoice.js';
import MakePayment from './Pages/Payments/MakePayment.js';
import Result from './Pages/Resullt/Result.js';
import ResultSheet from './Pages/Results Sheet/ResultSheet.js';
import Attendance from './Pages/Attendance/Attendance.js';
import Announcements from './Pages/Announcements/Announcements.js';
import Profile from './Pages/Profile/Profile.js';
import Infractions from "./Pages/Infactions/Infractions.js";
import Infractions2 from "./Pages/Infactions2/Infractions2.js";
import PaymentsInvoice from './Pages/Payments/PaymentsInvoice.js';
import Payments from './Pages/The Payment/Payments.js';
import Assignment from './Pages/Assignment/Assignment.js';
import NewAssignment from './Pages/New Assignment/NewAssignment.js';
import SubmitAssignment from './Pages/SubmitAssignment/SubmitAssignment.js';
import UploadAssignment from './Pages/UploadAssignment/UploadAssignment.js';
import AssignmentSubmit from './Pages/AssignmentSubmit/AssignmentSubmit.js';

function App() {
  return (
      <Routes>
        <Route path='/' element={<Login/>}/>
        <Route path='/signup' element={<SignUp/>} />
        <Route path='/dashboard' element={<Dashboard/>} />
        <Route path='/payments_invoice' element={<PaymentsInvoice/>}/>
        <Route path='/payments' element={<Payments/>}/>
        <Route path='/make_payment' element={<MakePayment/>}/>
        <Route path='/results' element={<Result/>} />
        <Route path='/attendance' element={<Attendance/>} />
        <Route path='/annoucements' element={<Announcements/>} />
        <Route path='/profile' element={<Profile/>} />
        <Route path='/result_sheet' element={<ResultSheet/>} />
        <Route path='/infractions' element={<Infractions/>} />
        <Route path='/infractions2' element={<Infractions2/>} />
        <Route path='/assignment' element={<Assignment/>} />
 <Route path='/newassign' element={<NewAssignment/>} />
           <Route path='/submitassign' element={<SubmitAssignment/>} />
       <Route path='/uploadassign' element={<UploadAssignment/>} />
     <Route path='/assignconf' element={<AssignmentSubmit/>} />
      </Routes>
  );
}

export default App;
