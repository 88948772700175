import React, { useState, useEffect, Children } from 'react';
import classes from '../SubmitAssignment/SubmitAssignment.module.css';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import pfp from "../../Assets/pfp.png";
import MainDashboard from '../Main Dashboard/MainDashoard';
import FemaleIcon from '../../assets/promix/MyUser.svg'
import MaleIcon from '../../assets/promix/MaleIcon.svg'
import Payment from '../../assets/promix/detailsIcon1.svg'
import Payment2 from '../../assets/promix/detailsIcon2.svg'
import Curve from '../../assets/promix/curve1.svg'
import Boy from '../../assets/promix/FineBoy.svg'
import Form from 'react-bootstrap/Form';
// import { Link, useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Promix/api/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import back from '../../assets/backicon.svg'


import { Link, useLocation, useNavigate } from 'react-router-dom'

function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - dob.getFullYear();

    if (currentDate.getMonth() < dob.getMonth() ||
        (currentDate.getMonth() === dob.getMonth() && currentDate.getDate() < dob.getDate())) {
        age--;
    }

    return age;
}


const SubmitAssignment = () => {
    const [bearer, setBearer] = useState('');
    const [children, setChildren] = useState([]);
    const [childrenDetails, setChildrenDetails] = useState([]);
    const [childrenSubjects, setChildrenSubjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadings, setIsLoadings] = useState(false);
    const [selectedChild, setSelectedChild] = useState("")
    const [parentName, setParentName] = useState("")


    const renderAge = () => {
        const selectedChildObj = children.find(child => child.id === selectedChild);
        if (selectedChildObj) {
            const age = calculateAge(selectedChildObj.date_of_birth);
            return <span>{age} years old</span>;
        }
        return null;
    }


    const handleChildrenChange = (event) => {
        setSelectedChild(event.target.value)
    }

    const readData = async () => {
        try {
            const details = await AsyncStorage.getItem('userToken');
            const parent = await AsyncStorage.getItem('userName')

            if (details !== null) {
                setBearer(details);
            }
            if (parent !== null) {
                setParentName(parent)
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    const fetchAllChildren = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/get-all-children`, { headers });
            const result = response.data?.data
            setChildren(result);
            setSelectedChild(result[0]?.id);
            console.log('All children', result)
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    }


    const fetchChildrenDetail = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/get-student-by-id?student_id=${selectedChild}`,
                { headers });
            const details = response.data?.data
            setChildrenDetails(details);
            // console.log(details, "wehereh");
            console.log(details, "Children Details");
            setChildrenSubjects(details?.classes?.assigned);
            // console.log(details?.classes?.assigned, "hereee");
            // console.log("childrenDetails:", childrenDetails);
            // console.log("school:", childrenDetails?.school);
            // console.log("school name:", childrenDetails?.school?.name);
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
            toast.error(errorMessage);
        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        if (bearer) {
            fetchAllChildren();
        }
    }, [bearer]);

    useEffect(() => {
        if (bearer) {
            fetchChildrenDetail(selectedChild);
        }
    }, [selectedChild, bearer]);


    return (
        <div>
            < MainDashboard />
            <div className={classes.formSection}>
                <div className={classes.formSectionHeaderContainer}>
                    <div className={classes.formSectionHeader}>
                        <div style={{ textAlign: 'left' }}>
                            <p style={{ margin: '0' }}>Welcome</p>
                            <h3>
                                {parentName}
                            </h3>

                        </div>
                        <div>
                            <h3 style={{ color: 'black' }}>Assignment</h3>
                        </div>
                        <div className={classes.users}>
                            <Form.Select aria-label="Default select example"
                                value={selectedChild}
                                onChange={handleChildrenChange}
                            >
                                <option key="">Select a Child</option>
                                {children.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.first_name} {item.last_name}
                                        {item.gender === 'male' ? (
                                            <img src={MaleIcon} alt='Male' />
                                        ) : (
                                            <img src={FemaleIcon} alt='Female' />
                                        )}
                                    </option>
                                ))}
                            </Form.Select>
                            {/* <h6 >Mosumola Lawanson</h6>
                            <img src={USER} alt='User'/>
                            <i class='bx bxs-chevron-down'></i> */}
                        </div>
                    </div>
                </div>

                <div className={classes.chartCont}>
                    <div className={classes.chartCont}>
                        <div className={classes.subContainer}>
                            <img src={back} className={classes.bckikon} />
                            <p>Assignments</p>
                        </div>
                        <div className={classes.assignmentdetailscontainer}>
                            <div className={classes.assignmentheader}>
                                <h4 className={classes.assignmenttitle}>Principles of debugging</h4>
                                <p className={classes.assignmentsummary}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. </p>
                                <div className={classes.assignmentdates}>
                                    <span className={classes.uploaddate}><p className={classes.uploaddate1}>Uploaded on</p> <p className={classes.uploaddate2}>25/05/2024</p></span>
                                    <span className={classes.submitdate}><p className={classes.submitdate1}>Submit on</p> <p className={classes.submitdate2}>30/05/2024</p></span>
                                </div>
                            </div>
                            <div className={classes.assignmentdetails}>
                                <h4>Assignment details</h4>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt
                                    explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
                                    dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
                                    nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur,
                                    vel illum qui dolorem eum fugiat quo voluptas nulla pariatur</p>

                            </div>
                            <Link to={'/uploadassign'}><button className={classes.submitassignmentbutton}>Submit Assignment</button></Link>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    );
}

export default SubmitAssignment;
